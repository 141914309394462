'use client';

import { buttonStyles } from '@/app/style-utils';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { generateColorPalette } from '@/utils/color-utils';

export function ServerStyles({
  application,
}: {
  application: RewardsCentralConfiguration['application'];
}) {
  const {
    colors,
    headingFont,
    overlayStyle,
    customBorderRadius,
    contentBackgroundColor,
  } = application.theme;
  const buttons = application.components.buttons;
  const navigation = application.components.navigation;
  const header = application.components.header;

  // For body color, we fallback to black so we won't need
  // to have fallback color in the css variable
  // the fallback value is in the root layout
  const bodyColorCSSVariable = colors?.body
    ? `--rc-color-body: ${colors.body};`
    : '--rc-color-body: #141414;';

  // TODO: This only apply to Money 20/20 and Ascenda page
  // we will override text-black and text-neutral-900 to use body color
  // if we have body color config
  const overrideItemTextColorStyle = colors?.body
    ? `
      .text-black, .text-neutral-900 {
        color: var(--rc-color-body) !important;
      }
    `
    : '';

  const overlayStyleCSSVariable = overlayStyle
    ? `--rc-over-bg-overlay: ${overlayStyle};`
    : '';

  const contentBackgroundColorVariable = contentBackgroundColor
    ? `--rc-color-content-background: ${contentBackgroundColor};`
    : '';

  const buttonLargePaddingVariable = buttons.config?.padding?.large
    ? `--rc-btn-large-padding: ${buttons.config?.padding?.large};`
    : '';

  const buttonMediumPaddingVariable = buttons.config?.padding?.medium
    ? `--rc-btn-medium-padding: ${buttons.config?.padding?.medium};`
    : '';

  // we don't need to set up fallback font here because if heading font is not set
  // we will use the the bodyFont that is already set up in the <body> tag
  // the only reason we create a const here is to have have this comment
  const headingFontConfig = `var(--rc-font-${headingFont})`;
  const radiusVariable = customBorderRadius
    ? `--rc-custom-radius: ${customBorderRadius};`
    : '';

  return (
    <style>
      {`
        :root {
            ${generateColorPalette('primary', colors.primary)}
            ${generateColorPalette('secondary', colors.secondary)}
            ${generateColorPalette('header', header.color)}
            ${generateColorPalette('bg-header', header.backgroundColor)}
            ${generateColorPalette(
              'footer',
              application.components.footer.color,
            )}
            ${buttonStyles({
              variant: 'primary',
              borderRadius: buttons.primary.borderRadius,
              borderWidth: buttons.primary.borderWidth,
            })}
            ${buttonStyles({
              variant: 'secondary',
              borderRadius: buttons.secondary.borderRadius,
              borderWidth: buttons.secondary.borderWidth,
            })}
            --rc-font-heading: ${headingFontConfig};

            --rc-header--bg-color: ${header.backgroundColor};
            --rc-header--color: ${header.color};
            --rc-menu-item--color: ${header.color};
            --rc-menu-item--hover-color: ${header.color};
            --rc-menu-item--hover-bg-color: ${
              navigation?.hoverBackgroundColor || 'transparent'
            };
            --rc-menu-item--description-color: ${
              navigation?.descriptionColor || 'var(--neutral-600)'
            };
            --rc-menu-item--icon-fill-color: ${header.color};
            ${bodyColorCSSVariable}
            ${overlayStyleCSSVariable}
            ${radiusVariable}
            ${contentBackgroundColorVariable}
            ${buttonLargePaddingVariable}
            ${buttonMediumPaddingVariable}
          }
          ${overrideItemTextColorStyle}
      `}
    </style>
  );
}
