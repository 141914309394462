'use client';

import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import Script from 'next/script';

export function Chatbot({ nonce }: { nonce: string | null }) {
  // Chatbots are only going to be present for M20 tenants as per
  // https://kaligo.slack.com/archives/C04EQEZMMQE/p1698304029701839?thread_ts=1698303980.199869&cid=C04EQEZMMQE
  const isM2020 = useFlagsReady('m2020_tenants');

  return (
    <>
      {isM2020 && (
        <>
          <Script id="chat-bot">
            {`
              window.embeddedChatbotConfig = {
                chatbotId: "AOXH359tjK_S0OAY_ar6X",
                domain: "www.chatbase.co"
              }
          `}
          </Script>
          <Script
            src="https://www.chatbase.co/embed.min.js"
            // @ts-expect-error: we need to pass the chatbotId to the script
            // for it to work
            chatbotId="AOXH359tjK_S0OAY_ar6X"
            domain="www.chatbase.co"
            strategy="lazyOnload"
            nonce={nonce ?? ''}
          ></Script>
        </>
      )}
    </>
  );
}
