export function handleRedirectAfterLogin({
  pathname,
  savedUrl,
  searchParams,
  homePagePathname,
}: {
  pathname: string;
  savedUrl?: string;
  searchParams: URLSearchParams;
  homePagePathname?: string;
}) {
  let nextPathname = pathname;
  let shouldRedirect = false;

  if (savedUrl) {
    const savedUrlObject = new URL(savedUrl);
    if (savedUrlObject.pathname !== '/' || homePagePathname) {
      nextPathname = savedUrlObject.pathname;
      shouldRedirect = true;
    }
  }

  const newSearchParams = new URLSearchParams(searchParams);
  if (newSearchParams.get('state')) {
    shouldRedirect = true;
    // clean up authentication params
    newSearchParams.delete('code');
    newSearchParams.delete('state');
    return {
      shouldRedirect,
      nextPathname: joinUrlWithSearchParams({
        homePagePathname,
        pathname: nextPathname,
        searchParams: newSearchParams,
      }),
    };
  }
  // else {
  //   // Do not redirect if the next pathname is the same as the current one
  //   // to avoid redirect loop
  //   if (nextPathname !== pathname) {
  //     return {
  //       shouldRedirect,
  //       nextPathname: joinUrlWithSearchParams({
  //         homePagePathname,
  //         pathname: nextPathname, searchParams: newSearchParams
  //       }),
  //     }
  //   }
  // }

  // This is not a good way to handle redirect
  // we should refactor this logic later
  if (nextPathname === '/' && homePagePathname) {
    shouldRedirect = true;
  }

  return {
    shouldRedirect,
    nextPathname: joinUrlWithSearchParams({
      homePagePathname,
      pathname: nextPathname,
      searchParams: newSearchParams,
    }),
  };
}

function joinUrlWithSearchParams({
  pathname,
  homePagePathname,
  searchParams,
}: {
  pathname: string;
  homePagePathname?: string;
  searchParams?: URLSearchParams;
}) {
  let nextPathname = pathname;
  // support custom home page path name
  // for example Robinhood doesn't have home page
  // and we need to redirect to the /travel/flights
  if (homePagePathname && pathname === '/') {
    nextPathname = homePagePathname;
  }
  // use toString() here to check the URLSearchParams is empty or not
  // since .size property is always undefined in vitest
  if (searchParams?.toString()) {
    return `${nextPathname}?${searchParams.toString()}`;
  }
  return nextPathname;
}
