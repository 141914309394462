import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import {
  getLocalTimeZone,
  parseDate,
  parseDateTime,
  toZoned,
} from '@internationalized/date';

export function safeParseDateRange({
  start,
  end,
}: {
  start: string | null;
  end: string | null;
}) {
  if (!start) {
    // console.error('startDate is null or empty string');
    return null;
  }
  if (!end) {
    // console.error('endDate is null or empty string');

    return null;
  }
  return {
    start: parseDate(start),
    end: parseDate(end),
  };
}

export function safeParseDate(date: string | null) {
  return date ? parseDate(date) : null;
}

export function safeParseDateTime(dateTime: string | null) {
  return dateTime ? parseDateTime(dateTime) : null;
}

/**
 * Convert ISO 8601 format date to ISO 8601 format with timezone
 * we only communicate with the server in UTC formate
 * NOTE: this function would throw error if the dateString is not the correct format
 * consumer need to handle the error explicit
 * https://www.notion.so/kaligo/Timezone-ab7cfb6fb1f44060aeac16a1700906cb?pvs=4
 * @param dateString ISO 8601 format date yyyy-MM-dd
 * @returns ISO 8601 format with timezone yyyy-MM-ddTHH:mm:ss.sssZ
 */
export function dateStringToAbsoluteString(dateString: string) {
  return toZoned(parseDate(dateString), getLocalTimeZone()).toAbsoluteString();
}

/**
 * This function is used along with a date range filter value
 *
 * https://kaligo.slack.com/archives/C04EQEZMMQE/p1686044950008569
 * @param dateString ISO 8601 format date yyyy-MM-dd
 * @returns start of the input date in ISO 8601 format with timezone yyyy-MM-ddTHH:mm:ss.sssZ
 */
export function startDateFilterRange(dateString: string) {
  return toZoned(parseDate(dateString), getLocalTimeZone())
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toAbsoluteString();
}

/**
 * This function is used along with a date range filter value
 *
 * We are using filter[transaction_time][$lt] operator
 * e.g. less them let's say: selected end date is 2023-06-06 +08:00
 * we want to include all the transactions that happened on 2023-06-06 +08:00
 * so we need to add 1 day to the selected end date. Then get the start of that day
 * 2023-06-07T00:00:00.000+08:00 then convert it UTC = 2023-06-06T16:00:00.000Z
 *
 * @param dateString ISO 8601 format date yyyy-MM-dd
 * @returns start date of the NEXT day ISO 8601 format with timezone yyyy-MM-ddTHH:mm:ss.sssZ
 */
export function endDateFilterRange(dateString: string) {
  return toZoned(parseDate(dateString), getLocalTimeZone())
    .add({
      days: 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toAbsoluteString();
}

export function displayOrderID(id: string) {
  return id.toUpperCase();
}

export function isPointsActivityEnabled(
  config: RewardsCentralConfiguration['application']['features']['rewardsSummary'],
) {
  // if the config is undefined, we assume it is enabled for backward compatibility
  return (
    config.pointsActivity?.enabled === undefined ||
    config.pointsActivity?.enabled === true
  );
}
