import { useTranslation } from '@/translation/translation';
import { FooterLink } from '../footer-link/footer-link';
import { ascendaLinkDisplayName } from './constants';
import type { AscendaLinkProps } from './types';

export function AscendaLink({
  className,
  hideBranding,
  ...props
}: AscendaLinkProps) {
  const { t } = useTranslation();

  if (hideBranding) {
    return null;
  }

  return (
    <FooterLink
      {...props}
      className={className}
      href="https://ascendaloyalty.com"
    >
      {t('Powered by Ascenda')}
    </FooterLink>
  );
}

AscendaLink.displayName = ascendaLinkDisplayName;
