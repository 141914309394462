import { MenuItemType, MenuNameType } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useNavigationMenuData } from './use-navigation-menu-data';

export const useHeaderLinks = (
  config: RewardsCentralConfiguration['application']['features'],
  navigationConfig?: RewardsCentralConfiguration['application']['components']['navigation'],
) => {
  const { defaultHeaderLinkMap, routes } = useNavigationMenuData(config);

  const enabledFeatureMap = new Map<MenuNameType, MenuItemType>();

  for (const route of routes) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const featureName = route as typeof defaultHeaderLinkMap extends Map<
      infer K,
      infer _Value
    >
      ? K
      : never;

    if (typeof route === 'string' && defaultHeaderLinkMap.has(featureName)) {
      enabledFeatureMap.set(
        featureName,
        defaultHeaderLinkMap.get(featureName)!,
      );
    }
  }

  // mixed with navigation config and sorted based on the ui settings
  const headerLinks: MenuItemType[] = [];
  if (navigationConfig?.orderedMenuItems) {
    const { orderedMenuItems } = navigationConfig;
    orderedMenuItems.map((configurableItem) => {
      if (enabledFeatureMap.has(configurableItem.name)) {
        const mappedMenuItem = enabledFeatureMap.get(configurableItem.name)!;
        if ('iconVisible' in configurableItem) {
          headerLinks.push({
            ...mappedMenuItem,
            iconVisible: configurableItem.iconVisible!,
          });
        } else {
          headerLinks.push({ ...mappedMenuItem });
        }
      }
    });
  } else {
    enabledFeatureMap.forEach((value) => {
      if (['earn', 'redeem'].includes(value.groupType)) {
        headerLinks.push(value);
      }
    });
  }

  const earnHeaderLinks: MenuItemType[] = headerLinks.filter(
    (item) => item.groupType === 'earn',
  );

  const redeemHeaderLinks: MenuItemType[] = headerLinks.filter(
    (item) => item.groupType === 'redeem',
  );

  let accountHeaderLinks: MenuItemType[] = [];
  enabledFeatureMap.forEach((value) => {
    if (value.groupType === 'myAccount') {
      accountHeaderLinks.push(value);
    }
  });

  return {
    headerLinks,
    earnHeaderLinks,
    redeemHeaderLinks,
    accountHeaderLinks,
  };
};
