'use client';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { getClientUrl } from '@/utils/get-client-url';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

const responseFormatHeaders = new Headers({ 'x-response-format': 'custom' });
// use zod here to improve typescript inference
export const isAcceptedTcEnum = z.enum(['loading']);

export function useIsAcceptedTcMutation() {
  const queryClient = useQueryClient();
  const authedFetch = useAuthFetchKy;
  const path = `${getClientUrl()}/api/user_customizations`;

  return useMutation({
    mutationFn: async (requestData: {
      isAcceptedTc: boolean;
      termsVersion: string;
      serviceTermsVersion: string;
      policyVersion: string;
    }) => {
      const mutationBody = {
        user_customizations: {
          is_accepted_tc: requestData.isAcceptedTc,
          terms_version: requestData.termsVersion,
          policy_version: requestData.policyVersion,
          service_terms_version: requestData.serviceTermsVersion,
        },
      };

      return authedFetch.put(path, { json: mutationBody });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user_customizations'],
      });
    },
  });
}
