import { useMemo, useState } from 'react';

import { useHermes } from '@/auth/use-hermes';
import { Avatar, getAvatarNameFromUser } from '@/components/avatar';
import { HeaderRcLink } from '@/components/header/header';
import { CloseIcon } from '@/components/icons/close-icon';
import { RcLink } from '@/components/rc-link';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { Button } from '@/design-system-components/button/button';
import Drawer, {
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import { useProfileLinks } from '@/hooks/config/use-profile-links';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';

import { Icon } from '../icon';

export const MobileAvatarHeader = (
  props: RewardsCentralConfiguration['application']['components']['header'] & {
    className?: string;
  },
) => {
  const profileLinks = useProfileLinks();
  const { loggedIn, logout, user } = useHermes();
  const avatarName = useMemo(() => getAvatarNameFromUser(user), [user]);
  const [isOpen, setIsOpen] = useState(false);
  const { data: pointsAccount } = usePointsAccounts();
  const pointsFormatter = usePointsFormatter();
  const { t } = useTranslation();

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event instanceof KeyboardEvent &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        onClick={toggleDrawer}
        aria-expanded={isOpen}
        data-testid="avatar-dropdown"
        aria-label={isOpen ? t('Close Profile Menu') : t('Open Profile Menu')}
        className={cn(props?.className)}
      >
        <Avatar
          name={avatarName}
          picture={user?.picture}
          variant={props?.avatar}
        />
      </button>
      <Drawer variant="right" isOpen={isOpen} onOpenChange={setIsOpen}>
        <DrawerHeader className="mb-4 pt-4">
          <button
            onClick={toggleDrawer}
            className="flex"
            aria-label={t('Close Profile Menu')}
            data-testid="close-profile-menu"
          >
            <CloseIcon />
          </button>
        </DrawerHeader>
        <DrawerBody className="px-0">
          {loggedIn && (
            <div className="flex flex-col">
              <div className="mb-6 flex flex-col bg-neutral-200 px-4 py-6">
                {Boolean(pointsAccount) ? (
                  <>
                    {t('You have')}
                    <span className="text-lg font-bold">
                      {pointsFormatter(pointsAccount?.pointsBalance)}
                    </span>
                  </>
                ) : (
                  <>
                    <div
                      className={'mb-4 h-4 w-1/3 rounded bg-neutral-300'}
                    ></div>
                    <div className={'h-6 w-11/12 rounded bg-neutral-300'}></div>
                  </>
                )}
              </div>
              <ul className="flex flex-col gap-4 px-4">
                {profileLinks.map((d: HeaderRcLink) => (
                  <li key={d.href}>
                    <RcLink href={d.href} onClick={toggleDrawer}>
                      {d.translation}
                    </RcLink>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </DrawerBody>
        <DrawerFooter className="p-4">
          <Button
            className="rounded-lg"
            onPress={() => {
              logout?.();
            }}
            fullWidth="mobile"
          >
            <span className="flex gap-2">
              {t('Logout')}
              <Icon
                name="arrow-right-to-bracket"
                className="h-4 w-4 fill-primary"
              />
            </span>
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};
