'use client';

import { useRouter } from '@/i18n/navigation';

import { Button } from '@/design-system-components/button/button';
import Drawer, {
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';
import { useLoyaltyCurrency } from '@/hooks/bootstrap/bootstrap-hooks';
import { useTranslation } from '@/translation/translation';
import { DialogTrigger } from 'react-aria-components';

import { ControlledDrawerWrapper } from '@/design-system-components/drawer/controlled-drawer-wrapper';
import { cn } from '@/utils/tailwind';
import { Icon } from './icon';

export interface IneligibleRedeemErrorDialogProps {
  open: boolean;
  onClose: () => void;
}

export function IneligibleRedeemErrorDialog(
  props: IneligibleRedeemErrorDialogProps,
) {
  const { t } = useTranslation();

  const loyaltyCurrency = useLoyaltyCurrency();
  const router = useRouter();
  const { open, onClose } = props;

  return (
    <DialogTrigger isOpen={open}>
      <Drawer
        desktopVariant="modal"
        className="flex items-center"
        modalClassName={cn(
          drawerModalClass.animation,
          drawerModalClass.dimensions,
          'relative lg:fixed',
        )}
        dialogClassName="flex w-full flex-col"
        data-testid="ineligible-redeem-dialog"
        variant="modal"
      >
        {({ close }) => (
          <ControlledDrawerWrapper onClose={onClose}>
            <div className="absolute right-4 top-5 lg:right-10">
              <Button
                data-testid="drawer-close-button"
                variant={'noBackground'}
                size={'icon'}
                onPress={() => {
                  close();
                  onClose();
                }}
              >
                <Icon className="h-5" name="xmark" />
              </Button>
            </div>
            <div className="mb-8 flex flex-col gap-6 lg:flex-row lg:items-center">
              <Icon name="user-xmark" className="h-8 w-8 text-lg text-error" />
              <span
                className="text-heading-2"
                data-testid="ineligible-redeem-dialog-modal-title"
              >
                {t('Ineligible to redeem')}
              </span>
            </div>
            <div className="mb-8">
              <span>
                {t(
                  'Sorry, we are unable to proceed with your redemption because you are currently ineligible to redeem ' +
                    loyaltyCurrency?.name,
                )}
              </span>
            </div>
            <div className="mb-8">
              <span>{t('For assistance, please visit our Help Center')}</span>
            </div>
            <div className="flex justify-end">
              <Button
                variant="primary"
                className="mb-1 mr-1 px-12 py-4"
                onPress={() => {
                  router.push('/help-center');
                  onClose();
                }}
              >
                {t('Go to Help Center')}
              </Button>
            </div>
          </ControlledDrawerWrapper>
        )}
      </Drawer>
    </DialogTrigger>
  );
}
