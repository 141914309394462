import { SupportedIconName } from '@/icons/supported-icon';
import { z } from 'zod';

const menuItemGroupTypeSchema = z.enum(['earn', 'redeem', 'home', 'myAccount']);
export const MenuItemNameSchema = z.enum([
  'giftCard',
  'sustainability',
  'pointsTransfer',
  'crypto',
  'cashback',
  'travel',
  'travelEarn',
  'offer',
  'cardLinkedOffer',
  'affiliateOffer',
  'diningOffer',
  'uberCredit',
  'appleMerchandise',
  // TODO: money 2020 demo, remove later
  'shopApple',
  'payWithPoints',
  'premiumBenefits',
  'myPointsActivity',
  'myRewards',
]);
export const NavigationSchema = z.object({
  hoverBackgroundColor: z.string().optional(),
  descriptionColor: z.string().optional(),
  orderedMenuItems: z
    .array(
      z.object({
        name: MenuItemNameSchema,
        iconVisible: z.boolean().optional(),
      }),
    )
    .optional(),
});

export type MenuItemGroupType = z.infer<typeof menuItemGroupTypeSchema>;
export type MenuNameType = z.infer<typeof MenuItemNameSchema>;
export type NavigationType = z.infer<typeof NavigationSchema>;

export interface MenuItemType {
  href: string;
  translation: string;
  shortDescription: string | null;
  groupType: MenuItemGroupType;
  iconHref?: string;
  iconName?: SupportedIconName;
  iconVisible: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMenuItem(obj: any): obj is MenuItemType {
  return typeof obj.href === 'string';
}
