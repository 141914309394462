'use client';

import { useRouter } from '@/i18n/navigation';
import { DevTool } from '@hookform/devtools';
import { Todo, WontFix } from 'global';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { DialogTrigger } from 'react-aria-components';
import { Controller, useForm } from 'react-hook-form';

import { useHermes } from '@/auth/use-hermes';
import { Markdown } from '@/components/markdown';
import { Button } from '@/design-system-components/button/button';
import { Checkbox } from '@/design-system-components/checkbox/checkbox';
import {
  Dialog,
  DialogDescription,
  DialogTitle,
} from '@/design-system-components/dialog/dialog';
import Drawer, {
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';
import { InfoContainer } from '@/design-system-components/info-container/info-container';
import { useTermsAndConditionsModalContent } from '@/hooks/static-documents/use-static-documents';
import {
  isAcceptedTcEnum,
  useIsAcceptedTcMutation,
} from '@/hooks/terms-and-conditions/terms-and-conditions-api';
import { useUserCustomizations } from '@/hooks/user-customizations/use-user-customizations';
import { useIsEnableTermsAndConditionsModal } from '@/store/store';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

function useClient() {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return isClient;
}

type SubmitData = {
  isAcceptedTc: true;
  policyVersion: string;
  termsVersion: string;
  serviceTermsVersion: string;
};

export function TermAndConditionsModal() {
  const isClient = useClient();
  const router = useRouter();
  const { data: isAcceptedTc, isError: isErrorIsAcceptedTc } =
    useUserCustomizations({ select: (data) => data.isAcceptedTc });

  const isMobile = useIsMobile();
  const isEnableTermsAndConditionsModal = useIsEnableTermsAndConditionsModal();
  useDebug();
  const isAcceptedTcMutation = useIsAcceptedTcMutation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    data,
    isLoading: isLoadingTcContent,
    isError: isErrorTcContent,
  } = useData();

  useEffect(() => {
    // we use the isOpen instead of isAcceptedTc because we want to
    // dismiss the modal when the user accepts the tc
    // without waiting for the API to return the value
    setIsOpen(!isAcceptedTc);
  }, [isAcceptedTc]);

  useEffect(() => {
    if (isErrorIsAcceptedTc) {
      router.push(
        // TODO: update message
        '/failure?error=Cannot+check+terms+and+conditions+acceptance&code=FE0000&httpCode=200',
      );
    }
  }, [isErrorIsAcceptedTc, router]);

  useEffect(() => {
    if (isErrorTcContent) {
      setIsOpen(false);
    }
  }, [isErrorTcContent]);

  if (!isEnableTermsAndConditionsModal || !isClient) {
    return null;
  }

  const onSubmit = async (submitData: SubmitData) => {
    // ppContent and tcContent should be defined at this point
    // otherwise we should navigate to the error page
    return await isAcceptedTcMutation
      .mutateAsync(submitData)
      .then(() => {
        setIsOpen(false);
      })
      .catch((error) => {
        console.log('error', error);
        // TODO: show inline error message
      });
  };

  // only the term and conditions modal if the user has not accepted the tc and
  // the API has returned the value
  if (isAcceptedTc === isAcceptedTcEnum.enum.loading || isErrorIsAcceptedTc) {
    return null;
  }

  return isMobile ? (
    <TcBottomDrawer
      isOpen={isOpen}
      isSubmitError={isAcceptedTcMutation.isError}
      onSubmit={onSubmit}
      data={data}
      isLoadingTcContent={isLoadingTcContent}
    />
  ) : (
    <TermAndConditionsModalDesktop
      isOpen={isOpen}
      isSubmitError={isAcceptedTcMutation.isError}
      onSubmit={onSubmit}
      data={data}
      isLoadingTcContent={isLoadingTcContent}
    ></TermAndConditionsModalDesktop>
  );
}

interface TcModalProps {
  isOpen: boolean;
  isSubmitError: boolean;
  onSubmit: (data: SubmitData) => Promise<void>;
  data: ReturnType<typeof useData>['data'];
  isLoadingTcContent: boolean;
}

function TcBottomDrawer({
  isOpen,
  isSubmitError,
  onSubmit,
  data,
  isLoadingTcContent,
}: TcModalProps) {
  return (
    <TcBottomDrawerUi
      data={data}
      isLoadingTcContent={isLoadingTcContent}
      isOpen={isOpen}
      isSubmitError={isSubmitError}
      onSubmit={() => {
        return onSubmit({
          isAcceptedTc: true,
          policyVersion: data?.privacyPolicy?.id || '',
          termsVersion: data?.termsConditions?.id || '',
          serviceTermsVersion: data?.serviceTerms?.id || '',
        });
      }}
    ></TcBottomDrawerUi>
  );
}

export function TcBottomDrawerUi({
  isOpen,
  isLoadingTcContent,
  data,
  onSubmit,
  isSubmitError,
}: TermAndConditionsModalUiProps) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  return (
    <DialogTrigger isOpen={isOpen}>
      <Drawer>
        {({ close }) => {
          return (
            <>
              {isSubmitError ? (
                <InfoContainer variant="error" className="mb-4">
                  {t('Something went wrong. Please try again later.')}
                </InfoContainer>
              ) : null}
              <DrawerHeader
                onClose={close}
                title={t('Our program terms and policies')}
              ></DrawerHeader>
              <DrawerBody
                disableScroll={true}
                className="flex flex-col overflow-hidden"
              >
                <TableOfContent containerRef={containerRef}></TableOfContent>
                <div
                  ref={containerRef}
                  className="mt-4 flex-1 overflow-y-scroll break-words rounded-lg bg-neutral-200 p-4"
                >
                  <TcContent
                    isLoading={isLoadingTcContent}
                    data={data}
                  ></TcContent>
                </div>
              </DrawerBody>
              <DrawerFooter>
                <TcForm
                  isLoadingTcContent={isLoadingTcContent}
                  onSubmit={onSubmit}
                ></TcForm>
              </DrawerFooter>
            </>
          );
        }}
      </Drawer>
    </DialogTrigger>
  );
}

// I tried ahooks and framer-motion useInView but they don't work
// in this case. They require the a React.ref to be passed in
// but in our case we need to query the DOM to get the element
// and then pass it to them
function useTableOfContentInView({
  elementId,
  containerRef,
}: {
  elementId: string;
  containerRef: React.RefObject<HTMLDivElement> | null;
}) {
  const [isInView, setIsInView] = useState(false);
  const { data } = useData();

  useEffect(() => {
    // Do not do anything until the tc content is loaded
    if (!data) {
      return;
    }

    if (!containerRef) {
      console.error('useTableOfContentInView: containerRef is null/undefined');
      return;
    }
    let timeoutId: WontFix;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { root: containerRef.current },
    );

    function startObserving() {
      timeoutId = setTimeout(() => {
        const tcTitleElement = document.getElementById(elementId);
        if (tcTitleElement) {
          observer.observe(tcTitleElement);
        }
      }, 100);
    }
    startObserving();

    return () => {
      observer?.disconnect();
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [data, containerRef, elementId]);

  return isInView;
}

function TermAndConditionsModalDesktop({
  isOpen,
  isSubmitError,
  onSubmit,
}: TcModalProps) {
  const { data, isLoading: isLoadingTcContent } = useData();

  return (
    <TermAndConditionsModalUi
      data={data}
      isLoadingTcContent={isLoadingTcContent}
      isOpen={isOpen}
      isSubmitError={isSubmitError}
      onSubmit={() => {
        return onSubmit({
          isAcceptedTc: true,
          policyVersion: data?.privacyPolicy?.id || '',
          termsVersion: data?.termsConditions?.id || '',
          serviceTermsVersion: data?.serviceTerms?.id || '',
        });
      }}
    ></TermAndConditionsModalUi>
  );
}

export interface TermAndConditionsModalUiProps {
  isOpen: boolean;
  isLoadingTcContent: boolean;
  data: Todo;
  onSubmit: () => Promise<void>;
  isSubmitError: boolean;
}
export function TermAndConditionsModalUiRadix({
  isOpen,
  isLoadingTcContent,
  data,
  onSubmit,
  isSubmitError,
}: TermAndConditionsModalUiProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        // the modal should be closed when the user accepts the tc
      }}
      contentWrapperClass="lg:max-w-[1008px] flex flex-col max-h-[calc(100vh-64px)]"
    >
      {isSubmitError ? (
        <InfoContainer variant="error" className="mb-6">
          {t('Something went wrong. Please try again later.')}
        </InfoContainer>
      ) : null}

      <div className="lg:12 mb-6 flex flex-col gap-6 lg:flex-row lg:items-center">
        <DialogTitle>{t('Our program terms and policies')}</DialogTitle>
      </div>
      <div className="flex flex-col overflow-hidden lg:flex-row">
        <TableOfContent containerRef={containerRef}></TableOfContent>
        <div
          className={cn(
            '"mt-4 flex flex-col overflow-hidden lg:mt-0 lg:flex-1',
            // prevent the checkbox/button outline being cut off
            'p-1',
          )}
        >
          <DialogDescription
            ref={containerRef}
            className="mb-6 max-h-[550px] overflow-y-scroll rounded-lg bg-neutral-200 p-4"
          >
            <TcContent isLoading={isLoadingTcContent} data={data}></TcContent>
          </DialogDescription>
          <TcForm
            isLoadingTcContent={isLoadingTcContent}
            onSubmit={onSubmit}
          ></TcForm>
        </div>
      </div>
    </Dialog>
  );
}

export function TermAndConditionsModalUi({
  isOpen,
  isLoadingTcContent,
  data,
  onSubmit,
  isSubmitError,
}: TermAndConditionsModalUiProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  return (
    <DialogTrigger isOpen={isOpen}>
      <Drawer
        desktopVariant="modal"
        className="flex items-center"
        modalClassName={cn(
          drawerModalClass.animation,
          'w-full rounded-lg bg-neutral-100 px-4 lg:p-10',
          'flex max-h-[calc(100vh-64px)] flex-col lg:max-w-[1008px]',
          'relative lg:fixed',
        )}
        dialogClassName="flex w-full flex-col"
        data-testid="ineligible-redeem-dialog"
        variant={'modal'}
      >
        {({ close }) => (
          <>
            {isSubmitError ? (
              <InfoContainer variant="error" className="mb-6">
                {t('Something went wrong. Please try again later.')}
              </InfoContainer>
            ) : null}
            <div className="lg:12 mb-6 flex flex-col gap-6 lg:flex-row lg:items-center">
              <span
                className="text-heading-2"
                data-testid="ineligible-redeem-dialog-modal-title"
              >
                {t('Our program terms and policies')}
              </span>
            </div>
            <div className="flex flex-col overflow-hidden lg:flex-row">
              <TableOfContent containerRef={containerRef}></TableOfContent>
              <div
                className={cn(
                  '"mt-4 flex flex-col overflow-hidden lg:mt-0 lg:flex-1',
                  // prevent the checkbox/button outline being cut off
                  'p-1',
                )}
              >
                <div
                  ref={containerRef}
                  className="mb-6 max-h-[550px] overflow-y-scroll rounded-lg bg-neutral-200 p-4"
                >
                  <TcContent
                    isLoading={isLoadingTcContent}
                    data={data}
                  ></TcContent>
                </div>
                <TcForm
                  isLoadingTcContent={isLoadingTcContent}
                  onSubmit={onSubmit}
                ></TcForm>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </DialogTrigger>
  );
}

function useData() {
  const { data, isLoading, isError } = useTermsAndConditionsModalContent();
  const router = useRouter();

  useEffect(() => {
    if (data === null) {
      // TODO: log Sentry error
      router.push(
        // TODO: update message
        '/failure?error=Some+Terms+and+Conditions+content+are+missing&code=FE0002&httpCode=200',
      );
    }
  }, [data, router]);

  useEffect(() => {
    if (isError) {
      // TODO: log Sentry error
      router.push(
        // TODO: update message
        '/failure?error=Cannot+get+Terms+and+Conditions+content&code=FE0001&httpCode=200',
      );
    }
  }, [isError, router]);

  return {
    isError,
    isLoading,
    data,
  };
}

const tcTitleId = 'tc-modal-terms-and-condition-title';
const ppTitleId = 'tc-modal-privacy-policy-title';
const serviceTermId = 'tc-modal-service-terms-title';

function TcContent({
  isLoading,
  data,
}: {
  isLoading: boolean;
  data: ReturnType<typeof useTermsAndConditionsModalContent>['data'];
}) {
  let content = '';

  if (isLoading || !data) {
    content = '';
    return (
      <div className="grid grid-cols-1 gap-2">
        {Array.from({ length: 20 }).map((_, index) => {
          return (
            <div
              key={index}
              className="h-5 animate-pulse rounded bg-neutral-400"
            ></div>
          );
        })}
      </div>
    );
  } else {
    // remark doesn't understand the self-closing tag here
    // it will wrap its below content in a div
    // add empty div here to support table of content scrolling
    content = `<div id="${tcTitleId}"> \n\n ${
      data.termsConditions?.content || ''
    } </div> <div id="${serviceTermId}"> ${
      data.serviceTerms?.content || ''
    } </div> <div id="${ppTitleId}"> ${
      data.privacyPolicy?.content || ''
    } </div>`;
  }
  return (
    <Markdown
      overrides={{
        p: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: HTMLAttributes<HTMLParagraphElement>;
        }) => <p {...props} className="mb-2" />,
      }}
    >
      {content}
    </Markdown>
  );
}

function TcForm({
  isLoadingTcContent,
  onSubmit,
}: {
  isLoadingTcContent: boolean;
  onSubmit: () => Promise<void>;
}) {
  const { isImpersonated, readOnly } = useHermes();
  const { t } = useTranslation();
  const { formState, handleSubmit, control } = useForm({
    defaultValues: {
      isAcceptedTc: false,
    },
  });

  return (
    <>
      <form
        onSubmit={handleSubmit(async () => {
          return onSubmit();
        })}
      >
        <div className="">
          <div>
            {t(
              'You may only access the site if you agree to everything above.',
            )}
          </div>
          <Controller
            name="isAcceptedTc"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                // TODO: move items-start and mt-[6px] to the checkbox component
                <Checkbox
                  className="mt-[6px] pb-0"
                  checkboxClassName={'lg:mt-0 lg:self-center'}
                  id="terms-and-conditions-modal-accept-checkbox"
                  isSelected={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                >
                  <span>
                    I agree to the <b>Terms of Use</b>,{' '}
                    <b>Customer Service Specific Terms</b> and{' '}
                    <b>Privacy Policy</b>.
                  </span>
                </Checkbox>
              );
            }}
          ></Controller>
        </div>
        {isImpersonated ? (
          <p className="mt-2 bg-error-200 p-4 text-error">
            {readOnly
              ? t(
                  'You are currently in read-only impersonation mode. Accepting these Terms and Conditions does not accept them on behalf of the user.',
                )
              : t(
                  'You are currently impersonating this user. Do not accept these Terms and Conditions, as you will be accepting them on behalf of the user.',
                )}
          </p>
        ) : null}
        <div className="mt-8">
          <Button
            variant="primary"
            type="submit"
            isLoading={formState.isSubmitting}
            isDisabled={!formState.isValid || isLoadingTcContent}
          >
            {t('Accept')}
          </Button>
        </div>
      </form>
      <DevTool control={control} />
    </>
  );
}

function TableOfContent({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const isInViewTc = useTableOfContentInView({
    elementId: tcTitleId,
    containerRef,
  });
  const isInviewPp = useTableOfContentInView({
    elementId: ppTitleId,
    containerRef,
  });
  const isInViewServiceTerm = useTableOfContentInView({
    elementId: serviceTermId,
    containerRef,
  });
  return (
    <ul className="flex w-full flex-col gap-3 lg:w-[280px] lg:gap-4">
      <li
        className={cn('cursor-pointer text-primary', {
          'font-bold': isInViewTc,
        })}
        onClick={() => {
          document
            .getElementById(tcTitleId)
            ?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {t('Terms of Use')}
      </li>
      <li
        className={cn('cursor-pointer text-primary', {
          'font-bold': isInViewServiceTerm,
        })}
        onClick={() => {
          document
            .getElementById(serviceTermId)
            ?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {t('Customer Service Specific Terms')}
      </li>
      <li
        className={cn('cursor-pointer text-primary', {
          'font-bold': isInviewPp,
        })}
        onClick={() => {
          document
            .getElementById(ppTitleId)
            ?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        {t('Privacy Policy')}
      </li>
    </ul>
  );
}

function useDebug() {
  const isAcceptedTcMutation = useIsAcceptedTcMutation();
  const { data } = useData();

  useEffect(() => {
    // TODO: debugging code. should delete this after QA testing this feature
    function handler() {
      isAcceptedTcMutation
        .mutateAsync({
          isAcceptedTc: false,
          policyVersion: data?.privacyPolicy?.id || '',
          termsVersion: data?.termsConditions?.id || '',
          serviceTermsVersion: data?.serviceTerms?.id || '',
        })
        .then(() => {
          window.alert(
            'You have successfully un-accepted the terms and conditions.',
          );
          window.location.reload();
        });
    }
    window.addEventListener('un-accept-tc', handler);

    return () => {
      window.removeEventListener('un-accept-tc', handler);
    };
  }, [isAcceptedTcMutation, data]);
}
