'use client';

import { WontFix } from 'global';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

import { useHermes } from '@/auth/use-hermes';
import { Container } from '@/components/container';
import { Markdown } from '@/components/markdown';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useTranslation } from '@/translation/translation';
import { useIsFailurePage } from '@/utils/page-url';
import { cn } from '@/utils/tailwind';
import { AscendaLink } from './ascenda-link/ascenda-link';
import { BrandLogoLink } from './brand-logo-link/brand-logo-link';
import { linkClasses } from './constants';
import { FooterLink } from './footer-link/footer-link';

interface FooterRcLink {
  href: string;
  translation: string;
}

export const FooterRcLinks: FooterRcLink[] = [
  { href: '/help-center', translation: 'Help Center' },
  {
    href: '/help-center/terms-and-conditions',
    translation: 'Terms of Use',
  },
  {
    href: '/help-center/customer-service-specific-terms',
    translation: 'Customer Service Specific Terms',
  },
  { href: '/help-center/privacy-policy', translation: 'Privacy Policy' },
];
export type FooterProps =
  RewardsCentralConfiguration['application']['components']['footer'] &
    RewardsCentralConfiguration['application']['theme'];

export function Footer(props: FooterProps) {
  const hermes = useHermes();
  const isFailurePage = useIsFailurePage();

  return isFailurePage ? null : <FooterUi {...props}></FooterUi>;
}

export function FooterUi(props: FooterProps) {
  const { t } = useTranslation();
  const tenantHubConfig = useTenantHubConfig();
  const isConfigAvailable = !!props;
  const footerCopyStyles = cn({
    [props?.textAlign]: isConfigAvailable,
    [props?.textDecoration]: isConfigAvailable,
    [props?.textTransform]: isConfigAvailable,
  });

  const footerLogo =
    props.logo === 'inverted' ? props.invertedLogo : props.brandLogo;

  // Logo: Use brandLogo from the theme, if footerLogo was not supplied
  return (
    <footer
      className={cn('pb-8 pt-6 text-footer lg:pt-8', footerCopyStyles)}
      style={{
        backgroundColor: tenantHubConfig?.footer_color ?? props.backgroundColor,
      }}
    >
      <Container>
        {(props.variant === 'footer-1' || props.variant === 'footer-2') && (
          <VariantOneAndTwoFooterLinks
            brandLogo={footerLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        )}
        {(props.variant === 'footer-3' || props.variant === 'footer-4') && (
          <VariantThreeAndFourFooterLinks
            brandLogo={footerLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        )}
        {(props.variant === 'footer-5' || props.variant === 'footer-6') && (
          <VariantFiveAndSixFooterLinks
            brandLogo={footerLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        )}
        {(props.variant === 'footer-2' ||
          props.variant === 'footer-4' ||
          props.variant === 'footer-6') && (
          <>
            <Markdown
              className={cn('mt-6', props.textAlign)}
              overrides={{
                a: ({
                  node,
                  ...props
                }: {
                  node: WontFix;
                  props: DetailedHTMLProps<
                    AnchorHTMLAttributes<HTMLAnchorElement>,
                    HTMLAnchorElement
                  >;
                }) => {
                  // only support open external link for now
                  const isHrefUrl =
                    // @ts-expect-error: TODO fix later
                    props?.href?.startsWith('http');
                  // DO NOT use RcLink or Next.js Link component here
                  // https://kaligo.atlassian.net/browse/BUG-2482
                  return isHrefUrl ? (
                    <a
                      {...props}
                      className={cn(linkClasses)}
                      target="_blank"
                      rel="noreferrer noopener"
                    />
                  ) : (
                    <a {...props} className={cn(linkClasses)} />
                  );
                },
              }}
            >
              {props?.content}
            </Markdown>
          </>
        )}

        {props.hideBranding ? null : (
          <p className="mt-4">
            {t(
              `© ${new Date().getFullYear()} Ascenda Loyalty Pte Ltd, All Rights Reserved`,
            )}
          </p>
        )}
      </Container>
    </footer>
  );
}

function VariantOneAndTwoFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  return (
    <div>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:flex-row lg:gap-x-10',
          {
            'justify-around': textAlign === 'text-center',
          },
        )}
        role="contentinfo"
      >
        {FooterRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink data-testid="footer-link" href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}

        <li className="mt-8 hidden flex-grow justify-end lg:mt-0 lg:block">
          <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-end">
            <BrandLogoLink logo={brandLogo} />
            <AscendaLink
              hideBranding={hideBranding}
              className={cn(textAlign)}
              data-testid="footer-logo"
            />
          </div>
        </li>
      </ul>
      <div className="mt-8 flex-grow justify-end lg:mt-0 lg:block lg:hidden">
        <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-end">
          <BrandLogoLink logo={brandLogo} />
          <AscendaLink
            hideBranding={hideBranding}
            className={cn(textAlign)}
            data-testid="footer-logo"
          />
        </div>
      </div>
    </div>
  );
}

function VariantThreeAndFourFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  return (
    <>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:flex-row lg:gap-x-10',
          { 'justify-around': textAlign === 'text-center' },
        )}
        role="contentinfo"
      >
        <li className="hidden flex-grow lg:flex">
          <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-start">
            <BrandLogoLink logo={brandLogo} />
            <AscendaLink
              hideBranding={hideBranding}
              className={cn(textAlign)}
              data-testid="footer-logo"
            />
          </div>
        </li>

        {FooterRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}
      </ul>
      <div className="mt-8 flex-grow justify-end lg:mt-0 lg:block lg:hidden">
        <div
          className={cn(
            'flex flex-col gap-4 lg:flex-row',
            { 'justify-center': textAlign === 'text-center' },
            { 'items-start': textAlign === 'text-left' },
          )}
        >
          <BrandLogoLink logo={brandLogo} />
          <AscendaLink
            hideBranding={hideBranding}
            className={cn(textAlign)}
            data-testid="footer-logo"
          />
        </div>
      </div>
    </>
  );
}

function VariantFiveAndSixFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  return (
    <>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:mt-0 lg:flex-row lg:gap-x-10',
          { 'justify-center': textAlign === 'text-center' },
        )}
        role="contentinfo"
      >
        {FooterRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}
      </ul>

      <div
        className={cn('mt-8 flex w-full flex-grow flex-col gap-6', {
          'items-center': textAlign === 'text-center',
        })}
      >
        <BrandLogoLink logo={brandLogo} />
        <AscendaLink hideBranding={hideBranding} data-testid="footer-logo" />
      </div>
    </>
  );
}
