'use client';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode, useEffect, useState } from 'react';

import { sendGTMEvent } from '@/app/[locale]/root-layout-config/nextjs-gtm';
import { authStateEnum } from '@/auth/auth-type';
import { useHermes } from '@/auth/use-hermes';
import { SpinnerIcon } from '@/components/icons/spinner-icon/spinner-icon';
import { BootstrapData } from '@/schema/bootstrap.schema';
import { AppEnv, useCustomizationConfigStore } from '@/store/store';
import { useIsPublicPage } from '@/utils/page-url';

export function BootstrapProvider({
  children,
  bootstrapData,
  appEnv,
}: {
  children: ReactNode;
  bootstrapData: BootstrapData | null;
  appEnv: AppEnv;
}) {
  const isPublicPage = useIsPublicPage();
  const configStore = useCustomizationConfigStore((state) => {
    return {
      updateBootstrapData: state.updateBootstrapData,
      bootstrapData: state.bootstrapData,
      updateAppEnv: state.updateAppEnv,
    };
  });
  const hermes = useHermes();

  useEffect(() => {
    const tenantId = configStore.bootstrapData?.tenantId;
    if (tenantId) {
      sendGTMEvent({
        event: 'tenant_ready',
        tenant_id: tenantId,
      });
    }
  }, [configStore.bootstrapData?.tenantId]);

  // use useState here to pass customizationConfig to children
  // otherwise the config is only available on client
  // in dev mode + StrictMode this function is executed twice
  useState(() => {
    configStore.updateBootstrapData(bootstrapData);
    configStore.updateAppEnv(appEnv);
  });

  // show the children only when the auth is initialized or the failure page
  return (
    <>
      {children}

      {
        // Do not show the spinner on the failure page
        hermes.authState !== authStateEnum.enum.login && !isPublicPage ? (
          <>
            <SpinnerIcon />
          </>
        ) : null
      }
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}
