'use client';

import { RedirectModalContext } from '@/app/redirect-modal-provider';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { Button } from '@/design-system-components/button/button';
import Drawer, {
  DrawerBody,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import { useTenantName } from '@/hooks/utils/use-tenant-name';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { useContext } from 'react';

const CARLTON_ONE_STOREFRONT_URL = '/auth/apple';

export default function RedirectModal() {
  const tenantName = useTenantName();
  const { t } = useTranslation();
  const { setShowRedirectModal, showRedirectModal } =
    useContext(RedirectModalContext);

  return (
    <Drawer
      isOpen={showRedirectModal}
      desktopVariant="modal"
      className="flex items-center"
      data-testid="redirect-modal-dialog"
      modalClassName={cn(
        '!duration-500 ease-out fill-mode-forwards',
        'rounded bg-white pb-6 pt-8 lg:w-[600px] lg:self-center lg:justify-self-center',
      )}
    >
      {({ close }) => {
        return (
          <>
            <DrawerHeader
              title={t(`You're about to leave ${tenantName}`)}
              className="pt-1 lg:gap-0 lg:pt-4"
              onClose={() => {
                setShowRedirectModal(false);
                close();
              }}
            />
            <DrawerBody>
              <p className="text-body">
                {t(
                  "You are now being redirected to our supplier's platform to browse and redeem Apple merchandise.",
                )}
              </p>
              <div className="mt-6 flex flex-grow flex-col-reverse justify-end gap-4 pb-1 lg:flex-row lg:gap-6">
                <Button
                  className="lg:block"
                  variant="secondary"
                  fullWidth="mobile"
                  data-testid="redirect-modal-close-button"
                  onPress={() => {
                    setShowRedirectModal(false);
                    close();
                  }}
                >
                  {t('No, stay here')}
                </Button>
                {/* this submit action needs to be a link because we are redirecting to an external site on a new tab */}
                <RcLink
                  variant={RcLinkVariant.BUTTON}
                  className="lg:block"
                  data-testid="redirect-modal-proceed-button"
                  target="_blank"
                  href={CARLTON_ONE_STOREFRONT_URL}
                  rel="noopener noreferrer"
                  prefetch={false}
                >
                  {t('Yes, Proceed')}
                </RcLink>
              </div>
            </DrawerBody>
          </>
        );
      }}
    </Drawer>
  );
}
