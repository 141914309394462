'use client';
import { LinkSlashIcon } from '@/components/icons/link-slash-icon';

interface BootstrapErrorProps {
  title?: string;
  body?: string;
}

// This might be seen even without Bootstrap. This is why:
// There are no translations (`useTranslate`) as translations come from Bootstrap.
// There are no icons (`useGetIcon`) as icons come form Bootstrap.
export default function BootstrapError({ title, body }: BootstrapErrorProps) {
  return (
    <html>
      <body className="h-screen w-screen">
        <div className="mx-auto flex h-full w-full flex-col justify-center px-6 lg:max-w-[600px] lg:px-0">
          <div className="mb-4 flex flex-col lg:mb-6 lg:flex-row">
            <LinkSlashIcon className="text-error mb-6 mr-auto inline-block h-8 lg:mb-0 lg:mr-2" />
            <h1 className="text-heading-2 font-bold text-black">
              {title ?? '404 Page not found'}
            </h1>
          </div>
          <p className="text-black">
            {body ??
              'Sorry, the page you are looking for does not exist anymore or there was an error in the link you followed or typed.'}
          </p>
        </div>
      </body>
    </html>
  );
}
