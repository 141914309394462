import { useMemo, useState } from 'react';

import { MenuItemType } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useHeaderLinks } from '@/hooks/config/use-header-links';

import { useHermes } from '@/auth/use-hermes';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { getAvatarNameFromUser } from '../avatar';
import { Icon } from '../icon';

export type NavigationListType = {
  groupName?: string;
  items: (MenuItemType | NavigationListType)[];
};

export const MobileHeaderV2 = (
  props: RewardsCentralConfiguration['application']['components']['header'] & {
    features: RewardsCentralConfiguration['application']['features'];
    navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
  },
) => {
  const { redeemHeaderLinks, earnHeaderLinks, headerLinks } = useHeaderLinks(
    props.features,
    props.navigationConfiguration,
  );
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useHermes();
  const { data: pointsAccount } = usePointsAccounts();
  const pointsFormatter = usePointsFormatter();
  const avatarName = useMemo(() => getAvatarNameFromUser(user), [user]);
  const fullName = `${user?.firstName} ${user?.lastName}`;

  const navigationList: NavigationListType = {
    items: [
      {
        href: '/',
        translation: 'Home',
        shortDescription: 'home',
        groupType: 'home',
        iconName: 'house-chimney',
        iconVisible: true,
      },
      {
        groupName: 'Earn',
        items: earnHeaderLinks,
      },
      {
        groupName: 'Redeem',
        items: redeemHeaderLinks,
      },
    ],
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        onClick={toggleDrawer}
        aria-expanded={isOpen}
        aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
        data-testid={isOpen ? 'close-menu' : 'open-menu'}
      >
        <Icon
          name="bars"
          fill={props.hamburgerIconColor ?? props.color}
          className="h-6 w-6"
        />
      </button>
    </>
  );
};
MobileHeaderV2.displayName = 'MobileHeaderV2';
