'use client';

import { usePathname } from '@/i18n/navigation';
import { useUnleashClient, useUnleashContext } from '@unleash/nextjs';
import { MouseEvent, useEffect } from 'react';

import { useHermes } from '@/auth/use-hermes';
import { Container } from '@/components/container';
import { HeaderDropdown } from '@/components/header/header-dropdown';
import { MobileHeader } from '@/components/header/mobile-header';
import { Logo } from '@/components/logo';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useIsSelectedUser } from '@/hooks/config/use-is-selected-user';
import { useIsFailurePage } from '@/utils/page-url';

import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { HeaderNavigation } from './header-navigation';
import { MobileAvatarHeader } from './mobile-avatar-header';

export interface HeaderRcLink {
  href: string;
  translation: string;
}

const linkClasses = 'text-header hover:text-header-300 hover:underline';

export function Header(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      features: RewardsCentralConfiguration['application']['features'];
      navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
    },
) {
  const hermes = useHermes();
  const pathName = usePathname();
  const updateUnleashContext = useUnleashContext();
  const isFailurePage = useIsFailurePage();
  const isSelected = useIsSelectedUser();
  const unleashClient = useUnleashClient();
  const tenantHubConfig = useTenantHubConfig();
  const tenantHubBrandLogo = tenantHubConfig?.logo_url ?? props.brandLogo;

  const unleashContext = unleashClient.getContext();
  useEffect(() => {
    if (isSelected) {
      updateUnleashContext({
        ...unleashContext,
        userId: 'selected_user',
      });
    }
    // updateUnleashContext is changed every reredener
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, unleashContext]);

  function onLinkClick(link: string, event: MouseEvent<HTMLAnchorElement>) {
    if (pathName === link) {
      event.preventDefault();
    }
  }

  // We don't want to show the header if the user is logged out
  // but we still want to show the header if the user is logged in or initializing the auth flow
  return isFailurePage ? null : (
    <header
      className="header sticky top-0 z-20 flex h-[72px] w-full flex-shrink-0 items-center border-b border-neutral-400"
      style={{
        background: tenantHubConfig?.header_color ?? props.backgroundColor,
      }}
    >
      <Container>
        <div className="z-50 flex lg:justify-between">
          <div className="space-between flex w-full items-center lg:hidden">
            <MobileHeader {...props} />
            {props.showLogo && (
              <Logo
                data-testid="header-logo"
                {...{ ...props, brandLogo: tenantHubBrandLogo }}
              />
            )}
            {hermes.loggedIn && (
              <MobileAvatarHeader className="ml-auto" {...props} />
            )}
          </div>

          <div className="hidden w-full items-center lg:flex lg:gap-x-12">
            {props.showLogo && (
              <Logo
                data-testid="header-logo"
                {...{ ...props, brandLogo: tenantHubBrandLogo }}
              />
            )}

            <HeaderNavigation {...props} />

            <div className="ml-auto flex flex-shrink-0 items-center gap-x-5 lg:gap-x-8">
              {hermes.loggedIn && <HeaderDropdown {...props} />}
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
}

/**
 TODO: Adding migration db for the new `navigation` settings:
 *
 * new `navigation` settings:
 {
   "application": {
    "components": {
      "navigation": {
        "hoverBackgroundColor": "#F8F8FA",
        "descriptionColor": "#818181",
        "orderedMenuItems": [
          { "name": "pointsTransfer"},
          { "name": "giftCard"},
          { "name": "travel"},
          { "name": "cashback"},
          { "name": "sustainability", "iconVisible": false },
          { "name": "crypto", "iconVisible": false },
          { "name": "offer", "iconVisible": false }
        ]
      },
    }
   }
  }

 */
