import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import * as Separator from '@radix-ui/react-separator';
import { useMemo } from 'react';

import { useHermes } from '@/auth/use-hermes';
import { Avatar, getAvatarNameFromUser } from '@/components/avatar';
import { RcLink } from '@/components/rc-link';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { Button } from '@/design-system-components/button/button';
import { useProfileLinks } from '@/hooks/config/use-profile-links';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';

import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { Icon } from '../icon';
import styles from './header-dropdown.module.css';

export const HeaderDropdown = (
  props: RewardsCentralConfiguration['application']['components']['header'] & {
    className?: string;
  },
) => {
  const { logout, user } = useHermes();
  const avatarName = useMemo(() => getAvatarNameFromUser(user), [user]);
  const { data: pointsAccount } = usePointsAccounts();

  const profileLinks = useProfileLinks();
  const pointsFormatter = usePointsFormatter();
  const { t } = useTranslation();

  const tenantHubConfig = useTenantHubConfig();
  const tenantHubHeaderFontColor = tenantHubConfig?.header_font_color;
  const navigationMenuContentStyles = cn(
    styles.NavigationMenuContent,
    'absolute left-auto right-0 top-full mt-6 rounded p-8',
  );

  // onPointerMove, onPointerLeave is due to Radix's NavigationMenu not supporting click instead of hover to open
  // https://github.com/radix-ui/primitives/issues/1630
  // https://codesandbox.io/s/navigation-menu-on-click-duwvgn?file=/App.js:8786-8861
  return (
    <NavigationMenu.Root
      className={cn('relative flex justify-center', props.className)}
    >
      <NavigationMenu.List className="list-style-none m-0 flex justify-center p-1">
        <NavigationMenu.Item
          className={cn('relative flex items-center gap-2 px-1')}
          style={{ color: tenantHubHeaderFontColor ?? props.color }}
        >
          {Boolean(pointsAccount) && (
            <>
              <p data-testid="header-points-balance">
                <span>{t('You have:')}</span>
                &nbsp;
                <span className="font-bold">
                  {pointsFormatter(pointsAccount?.pointsBalance)}
                </span>
              </p>
              <Separator.Root
                decorative
                orientation="vertical"
                className="mx-4 h-full w-[1px]"
                style={{
                  backgroundColor: tenantHubHeaderFontColor ?? props.color,
                }}
              />
            </>
          )}
          <NavigationMenu.Trigger
            className="focus-link flex items-center justify-center rounded-full"
            data-testid="header-avatar-dropdown"
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
          >
            <Avatar
              name={avatarName}
              picture={user?.picture}
              variant={props?.avatar}
            />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content
            className={cn(navigationMenuContentStyles, 'min-w-[320px]')}
            data-testid={'header-avatar-dropdown-menu'}
            onPointerLeave={(event) => event.preventDefault()}
          >
            <NavigationMenu.List>
              {profileLinks.map((d) => (
                <NavigationMenu.Item
                  key={d.href}
                  className="mb-3 flex last:mb-0"
                >
                  <NavigationMenu.Link asChild>
                    <RcLink
                      data-testid="header-avatar-dropdown-link"
                      href={d.href}
                    >
                      {d.translation}
                    </RcLink>
                  </NavigationMenu.Link>
                </NavigationMenu.Item>
              ))}
              <NavigationMenu.Item className="mt-6">
                <NavigationMenu.Link asChild>
                  <Button
                    data-testid="header-avatar-dropdown-logout"
                    variant={'primary'}
                    fullWidth={'always'}
                    textCenter
                    size={'md'}
                    onPress={() => {
                      logout?.();
                    }}
                  >
                    {t('Logout')}
                    <Icon
                      name="arrow-right-to-bracket"
                      className={cn('ml-2 h-4 w-4')}
                      style={{ fill: props.color }}
                    />
                  </Button>
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            </NavigationMenu.List>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};
