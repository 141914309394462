'use client';
import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import {
  UserCustomizationsResponse,
  userCustomizationsSchema,
} from '@/schema/user-customization/user-customization.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { useSentryMonitoring } from '../monitoring/use-sentry-monitoring';

const responseFormatHeaders = new Headers({ 'x-response-format': 'custom' });
// use zod here to improve typescript inference
export const isAcceptedTcEnum = z.enum(['loading']);

type UseUserCustomizationsParams<T> = {
  select?: (data: UserCustomizationsResponse) => T;
};

export function useUserCustomizations<T>({
  select,
}: UseUserCustomizationsParams<T>) {
  const baseUrl = getClientUrl();
  const path = `${baseUrl}/api/user_customizations`;
  const authFetch = useAuthFetchKy;
  const hermes = useHermes();
  const { sentryLog } = useSentryMonitoring();

  const query = useQuery({
    queryKey: ['user_customizations'],
    queryFn: async () => {
      const data = await authFetch
        .get(path, { headers: responseFormatHeaders })
        .json();

      const validatedData = validateSchema({
        data: data,
        schemaName: 'user-customizations-schema',
        zodSchema: userCustomizationsSchema,
      });

      return validatedData;
    },
    select,
    onError: (error: Error) => {
      sentryLog.error(
        `Error while fetching user_customizations ${error.toString()}`,
      );
    },
    retry: 3,
    // make sure that we don't refetch this query
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: hermes.loggedIn,
  });

  return {
    ...query,
    // we want to return 'loading' instead of undefined
    // to avoid the terms and conditions flash on the screen
    data: query.isFetched ? query.data : isAcceptedTcEnum.enum.loading,
  };
}
