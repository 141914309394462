import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { Icon } from '@/components/icon';
import { SupportedIconName } from '@/icons/supported-icon';
import { cn } from '@/utils/tailwind';

import { buttonVariants } from '../button/button-variants';

type Props = {
  variant: 'error' | 'info' | 'success' | 'warning';
  // Adding this custom icon props
  // cause we use different icons in different places
  // for the same variant
  icon?: SupportedIconName;
  className?: string;
  children?: ReactNode;
  close?: () => void;
};

export function InfoContainer({
  variant,
  children,
  className,
  icon,
  close,
}: Props) {
  const variantClass = cva('', {
    variants: {
      backgroundColor: {
        info: 'bg-info-200',
        error: 'bg-error-200',
        success: 'bg-success-100',
        warning: 'bg-warning-100',
      },
      icon: {
        info: 'circle-info',
        error: 'circle-exclamation',
        success: 'circle-check',
        warning: 'rectangle-xmark',
      },
      iconColor: {
        info: 'fill-info',
        error: 'fill-error',
        success: 'fill-success',
        warning: 'fill-warning',
      },
    },
  });
  return (
    <div
      data-testid="info-container"
      className={cn(
        'flex',
        'gap-2',
        'p-3',
        'rounded',
        className,
        variantClass({ backgroundColor: variant }),
      )}
    >
      <span>
        <Icon
          name={
            icon ??
            // This is expected cause variantClass icon will return SupportedIconName
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (variantClass({ icon: variant }) as SupportedIconName)
          }
          className={cn(
            variantClass({ iconColor: variant }),
            'h-4 w-4 flex-shrink-0 self-auto',
          )}
        />
      </span>
      <div>{children}</div>
      {close && (
        <button
          onClick={close}
          className={cn(
            buttonVariants({
              icon: 'iconOnly',
              size: 'icon',
              variant: 'noBackground',
              noBorder: true,
            }),
            'ml-auto',
          )}
        >
          <Icon
            name="xmark"
            className={cn(
              variantClass({ iconColor: variant }),
              'h-4 w-4 flex-shrink-0 self-auto',
            )}
          />
        </button>
      )}
    </div>
  );
}
