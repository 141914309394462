import { isPointsActivityEnabled } from '@/features/rewards-summary/shared/utils';
import { useFeatureConfig } from '@/hooks/config/use-feature-config';

export const useProfileLinks = () => {
  const data = useFeatureConfig();
  if (!data) {
    return [];
  }

  const profileLinks = [
    {
      href: '/rewards-summary/my-rewards',
      translation: 'My Rewards',
    },
  ];

  if (isPointsActivityEnabled(data.rewardsSummary)) {
    profileLinks.push({
      href: '/rewards-summary/points-activity',
      translation: 'Points Activity',
    });
  }

  return profileLinks;
};
