'use client';

import { Container } from '@/components/container';
import { RcLink } from '@/components/rc-link';
import { usePathname } from '@/i18n/navigation';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';

export function BypassBlock() {
  const { t } = useTranslation();
  const currentPage = usePathname();

  return (
    <>
      <Container className="w-full">
        <RcLink
          className={cn(
            'absolute translate-x-[-9999px]',
            'focus:inline-block focus:translate-x-0 focus:bg-primary',
            'z-[9] mt-[96px] font-bold',
            'px-12 py-4 text-white',
          )}
          href={`${currentPage}#main-content`}
        >
          {t('Skip to main content')}
        </RcLink>
      </Container>
    </>
  );
}
