import { flightRoutes } from '@/app/[locale]/travel/flights/routes';
import { useFlightsFlag } from '@/app/[locale]/travel/use-travel-flag';
import {
  MenuItemNameSchema,
  MenuItemType,
  MenuNameType,
} from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { BootstrapData } from '@/schema/bootstrap.schema';
import { useLoyaltyCurrency } from '../bootstrap/bootstrap-hooks';
import { useFlagsReady } from '../flags-ready/use-flags-ready';
import { useUberCredit } from '../loyalty-programs/use-uber-credit';

export function useNavigationMenuData(
  config: RewardsCentralConfiguration['application']['features'],
) {
  const {
    cashback,
    giftCard,
    crypto,
    sustainability,
    pointsTransfer,
    offer,
    cardLinkedOffer,
    affiliateOffer,
    travelEarn,
    appleMerchandise,
    uberCredit,
  } = config;

  const isM2020 = useFlagsReady('m2020_tenants');
  const isAppleMerchandiseFlagEnabled = useFlagsReady('apple-merchandise');
  const isOfferSeparation = useFlagsReady('offer-separation');
  const flightsFlag = useFlightsFlag();
  const isDiningOffersEnabled = useFlagsReady('dining-offers');
  const rewardsPortal2 = useFlagsReady('rewards_portal_2.0_r2');

  const loyaltyCurrency = useLoyaltyCurrency();
  const { uberLoyaltyProgramId } = useUberCredit({
    enabled: !!uberCredit?.enabled,
  });

  const defaultHeaderLinkMap = rewardsPortal2
    ? getDefaultHeaderLinkMapRC2_0(
        isM2020,
        loyaltyCurrency,
        uberLoyaltyProgramId,
      )
    : getDefaultHeaderLinkMap(isM2020, loyaltyCurrency, uberLoyaltyProgramId);

  let routes;

  // This implicitly defines the display order of the menu items
  // eslint-disable-next-line unicorn/prefer-ternary
  if (rewardsPortal2) {
    routes = [
      pointsTransfer.enabled && MenuItemNameSchema.enum.pointsTransfer,
      flightsFlag && MenuItemNameSchema.enum.travel,
      uberCredit?.enabled && MenuItemNameSchema.enum.uberCredit,
      giftCard.enabled && MenuItemNameSchema.enum.giftCard,
      crypto.enabled && MenuItemNameSchema.enum.crypto,
      sustainability.enabled && MenuItemNameSchema.enum.sustainability,
      cashback.enabled && MenuItemNameSchema.enum.cashback,
      isAppleMerchandiseFlagEnabled &&
        appleMerchandise?.enabled &&
        MenuItemNameSchema.enum.appleMerchandise,
      !isOfferSeparation && offer.enabled && MenuItemNameSchema.enum.offer,
      isOfferSeparation &&
        cardLinkedOffer.enabled &&
        MenuItemNameSchema.enum.cardLinkedOffer,
      isOfferSeparation &&
        affiliateOffer.enabled &&
        MenuItemNameSchema.enum.affiliateOffer,
      isDiningOffersEnabled && MenuItemNameSchema.enum.diningOffer,
      travelEarn?.enabled && MenuItemNameSchema.enum.travelEarn,
      isM2020 && MenuItemNameSchema.enum.shopApple,
      isM2020 && MenuItemNameSchema.enum.payWithPoints,
      isM2020 && MenuItemNameSchema.enum.premiumBenefits,
      'myPointsActivity',
      'myRewards',
    ] as const;
  } else {
    routes = [
      flightsFlag && MenuItemNameSchema.enum.travel,
      pointsTransfer.enabled && MenuItemNameSchema.enum.pointsTransfer,
      giftCard.enabled && MenuItemNameSchema.enum.giftCard,
      cashback.enabled && MenuItemNameSchema.enum.cashback,
      crypto.enabled && MenuItemNameSchema.enum.crypto,
      sustainability.enabled && MenuItemNameSchema.enum.sustainability,
      isAppleMerchandiseFlagEnabled &&
        appleMerchandise?.enabled &&
        MenuItemNameSchema.enum.appleMerchandise,
      !isOfferSeparation && offer.enabled && MenuItemNameSchema.enum.offer,
      isOfferSeparation &&
        cardLinkedOffer.enabled &&
        MenuItemNameSchema.enum.cardLinkedOffer,
      isOfferSeparation &&
        affiliateOffer.enabled &&
        MenuItemNameSchema.enum.affiliateOffer,
      isDiningOffersEnabled && MenuItemNameSchema.enum.diningOffer,
      isM2020 && MenuItemNameSchema.enum.shopApple,
      isM2020 && MenuItemNameSchema.enum.payWithPoints,
      isM2020 && MenuItemNameSchema.enum.premiumBenefits,
      travelEarn?.enabled && MenuItemNameSchema.enum.travelEarn,
      uberCredit?.enabled && MenuItemNameSchema.enum.uberCredit,
    ] as const;
  }

  // setup enabled features by default
  return {
    defaultHeaderLinkMap,
    routes,
  };
}

export const getDefaultHeaderLinkMap = (
  isM2020: boolean,
  loyaltyCurrency?: BootstrapData['loyaltyCurrencies'][0],
  uberLoyaltyProgramId?: string,
) =>
  new Map<MenuNameType, MenuItemType>([
    [
      'cashback',
      {
        href: '/products/cashback',
        translation: 'Cashback',
        shortDescription:
          'Convert to cash and get paid out using your preferred payment method',
        iconName: 'money-bill',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'giftCard',
      {
        href: '/products/gift-cards',
        translation: 'Gift Cards',
        shortDescription:
          'Shop for what you love most with digital gift cards from all major brands',
        iconName: 'gift',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'crypto',
      {
        href: '/products/crypto',
        translation: 'Crypto',
        shortDescription:
          'Unlock the future and redeem points for your favorite cryptocurrencies',
        iconName: 'wallet',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'sustainability',
      {
        href: '/products/sustainability',
        translation: 'Carbon Offset',
        shortDescription:
          'Make every point count for a better and greener planet',
        iconName: 'leaf',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'pointsTransfer',
      {
        href: '/products/frequent-traveler-programs',
        translation: isM2020 ? 'Points Transfer' : 'Frequent Traveler Programs',
        shortDescription: 'Enjoy redemptions with frequent traveler programs',
        iconName: 'plane-departure',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'travel',
      {
        href: flightRoutes.home,
        translation: 'Travel',
        shortDescription:
          'Book with points and choose from 200 airlines & 900,000 hotels globally',
        iconName: 'suitcase-rolling',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'offer',
      {
        href: '/products/offers',
        translation: 'Offers',
        shortDescription:
          'Enjoy the best life has to offer with exclusive and personalized offers',
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'diningOffer',
      {
        href: '/products/dining',
        translation: 'Dining Offers',
        shortDescription:
          'Earn points on your purchases through a single click via our merchant links',
        iconName: 'utensils',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'uberCredit',
      {
        href: `/products/uber-credit/${uberLoyaltyProgramId}`,
        translation: 'Uber Credit',
        shortDescription:
          'Turn points into Uber credit for convenient rides and food delivery',
        iconName: 'uber',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'cardLinkedOffer',
      {
        href: '/products/card-linked-offers',
        translation: 'Card-Linked Offers',
        shortDescription:
          'Earn points on your purchases when you make payment using an eligible card',
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'affiliateOffer',
      {
        href: '/products/affiliate-offers',
        translation: 'Affiliate Offers',
        shortDescription:
          'Earn points on your purchases through a single click via our merchant links',
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'travelEarn',
      {
        href: flightRoutes.home,
        translation: 'Travel',
        shortDescription:
          'Book and earn 10X points on travel for every dollar spent',
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'appleMerchandise',
      {
        href: 'https://abcrewards.us.kaligo-staging.xyz/auth/apple', // this would be just /auth/apple after integration
        translation: 'Apple Merchandise',
        shortDescription: `Redeem apple products using your ${loyaltyCurrency?.name}`,
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'shopApple',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65920&viewport=-225%2C-497%2C0.04&t=IX5lWMynLZCjYymt-0&scaling=min-zoom&starting-point-node-id=2062%3A65920&hide-ui=1',
        translation: 'Shop Apple',
        shortDescription:
          'Shop your favorite Apple products using points and cash',
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'payWithPoints',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-66637&viewport=-1930%2C-4828%2C0.11&t=YLmT9N0ef56Xd9AQ-8&scaling=min-zoom&starting-point-node-id=2062%3A66637&hide-ui=1',
        translation: 'Pay with Points',
        shortDescription:
          'Pay with your points at top merchants online or at the point of sale',
        iconName: 'sack-dollar',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'premiumBenefits',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65454&viewport=-225%2C-497%2C0.04&t=Gshscv4QMwa5NgIp-8&scaling=min-zoom&starting-point-node-id=2062%3A65454&hide-ui=1',
        translation: 'Premium Benefits',
        shortDescription:
          'Explore the premium benefits of being an awesome customer',
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
  ]);

export const getDefaultHeaderLinkMapRC2_0 = (
  isM2020: boolean,
  loyaltyCurrency?: BootstrapData['loyaltyCurrencies'][0],
  uberLoyaltyProgramId?: string,
) =>
  new Map<MenuNameType, MenuItemType>([
    [
      'cashback',
      {
        href: '/products/cashback',
        translation: 'Cash back',
        shortDescription:
          'Convert to cash and get paid out using your preferred payment method',
        iconName: 'money-bill',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'giftCard',
      {
        href: '/products/gift-cards',
        translation: 'Gift Cards',
        shortDescription:
          'Shop for what you love most with digital gift cards from all major brands',
        iconName: 'gift',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'crypto',
      {
        href: '/products/crypto',
        translation: 'Crypto',
        shortDescription: `Unlock the future and redeem ${
          loyaltyCurrency?.name || 'points'
        } for your favorite coins`,
        iconName: 'coins',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'sustainability',
      {
        href: '/products/sustainability',
        translation: 'Carbon',
        shortDescription: `Make every ${
          loyaltyCurrency?.name || 'points'
        } count for a better and greener planet`,
        iconName: 'leaf',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'pointsTransfer',
      {
        href: '/products/frequent-traveler-programs',
        translation: isM2020 ? 'Points Transfer' : 'Transfers',
        shortDescription: 'Enjoy redemptions with frequent traveler programs',
        iconName: 'rotate',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'travel',
      {
        href: flightRoutes.home,
        translation: 'Travel',
        shortDescription: `Book with ${
          loyaltyCurrency?.name || 'points'
        } and choose from 200+ airlines and 900k+ hotels globally`,
        iconName: 'suitcase-rolling',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'offer',
      {
        href: '/products/offers',
        translation: 'Offers',
        // TODO(Khoi): Update this rate to be dynamic
        shortDescription: `Earn up to 100 ${loyaltyCurrency?.name} per $1 on your shopping online or in-store`,
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'uberCredit',
      {
        href: `/products/uber-credit/${uberLoyaltyProgramId}`,
        translation: 'Uber',
        shortDescription: `Turn ${
          loyaltyCurrency?.name || 'points'
        } into Uber credit for convenient rides`,
        iconName: 'uber',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'cardLinkedOffer',
      {
        href: '/products/card-linked-offers',
        translation: 'Card-Linked Offers',
        shortDescription:
          'Earn points on your purchases when you make payment using an eligible card',
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'affiliateOffer',
      {
        href: '/products/affiliate-offers',
        translation: 'Affiliate Offers',
        shortDescription:
          'Earn points on your purchases through a single click via our merchant links',
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'travelEarn',
      {
        href: flightRoutes.home,
        translation: 'Travel',
        shortDescription:
          'Book and earn 10X points on travel for every dollar spent',
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'appleMerchandise',
      {
        href: '/auth/apple',
        translation: 'Apple',
        shortDescription: `Shop your favorite Apple products using ${loyaltyCurrency?.name} and cash`,
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'shopApple',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65920&viewport=-225%2C-497%2C0.04&t=IX5lWMynLZCjYymt-0&scaling=min-zoom&starting-point-node-id=2062%3A65920&hide-ui=1',
        translation: 'Shop Apple',
        shortDescription:
          'Shop your favorite Apple products using points and cash',
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'payWithPoints',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-66637&viewport=-1930%2C-4828%2C0.11&t=YLmT9N0ef56Xd9AQ-8&scaling=min-zoom&starting-point-node-id=2062%3A66637&hide-ui=1',
        translation: 'Pay with Points',
        shortDescription:
          'Pay with your points at top merchants online or at the point of sale',
        iconName: 'sack-dollar',
        groupType: 'redeem',
        iconVisible: true,
      },
    ],
    [
      'premiumBenefits',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65454&viewport=-225%2C-497%2C0.04&t=Gshscv4QMwa5NgIp-8&scaling=min-zoom&starting-point-node-id=2062%3A65454&hide-ui=1',
        translation: 'Premium Benefits',
        shortDescription:
          'Explore the premium benefits of being an awesome customer',
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
      },
    ],
    [
      'myPointsActivity',
      {
        href: '/rewards-summary/points-activity',
        translation: 'My points activity',
        shortDescription: `Track your ${
          loyaltyCurrency?.name || 'points'
        } balance using your earn and redeem history`,
        groupType: 'myAccount',
        iconVisible: false,
      },
    ],
    [
      'myRewards',
      {
        href: '/rewards-summary/my-rewards',
        translation: 'My rewards',
        shortDescription:
          'Everything you’ve ever redeemed – in one single place',
        groupType: 'myAccount',
        iconVisible: false,
      },
    ],
  ]);
