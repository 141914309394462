'use client';
import { MenuItemType } from '@/config-schema/navigation';
import React from 'react';

export function customNavigation(
  item: MenuItemType,
  event: React.MouseEvent<HTMLAnchorElement>,
  setShowRedirectModal: (show: boolean) => void,
) {
  if (['apple merchandise', 'apple'].includes(item.translation.toLowerCase())) {
    event.preventDefault();
    setShowRedirectModal(true);
  }
}
