'use client';

import {
  usePathname,
  useRouterWithoutLocalePrefix,
  useSearchParams,
} from '@/i18n/navigation';
import { Todo } from 'global';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { AuthContext, AuthContextConfig } from '@/auth/auth-context';
import { hermesServiceEventType } from '@/auth/auth-type';
import { useIsPublicPage, useIsSessionExpired } from '@/utils/page-url';

import { handleRedirectAfterLogin } from '@/auth/redirect-logic';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { useCustomizationConfigStore } from '@/store/store';
import { isInitialized, setUser } from '@sentry/nextjs';
import { type AuthService } from '../auth/auth-service';
import { flightRoutes } from './[locale]/travel/flights/routes';
import { useAuthStore } from './auth-store';

function SetSentryUser({
  user,
  children,
}: {
  user: Todo;
  children: ReactNode;
}) {
  const { sub, tenantId } = user || { sub: null, tenantId: null };

  const isSentryReady = isInitialized();
  const isSentryUserSet = useRef<boolean>(false);
  useEffect(() => {
    if (!isSentryUserSet.current && isSentryReady && sub && tenantId) {
      /** note: concat tenantId with sub (user ID) to form the Sentry user ID */
      setUser({ id: `${tenantId}:${sub}` });
      isSentryUserSet.current = true;
    }
  }, [isSentryReady, sub, tenantId]);

  if (isInitialized()) {
    setUser({
      id: sub,
    });
  }

  return <>{children}</>;
}

export function AuthProvider({
  children,
  hermesConfig,
  isRobinhood,
}: {
  children: React.ReactNode;
  hermesConfig: AuthContextConfig;
  isRobinhood: boolean;
}) {
  const isSessionExpired = useIsSessionExpired();
  const isPublicPage = useIsPublicPage();
  const router = useRouterWithoutLocalePrefix();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { updateShouldAllowHomeRedirect } = useCustomizationConfigStore(
    (state) => {
      return {
        updateShouldAllowHomeRedirect: state.updateShouldAllowHomeRedirect,
      };
    },
  );
  const isInitialized = useRef(false);
  const { sentryLog } = useSentryMonitoring();

  const {
    dispatch,
    state: {
      user,
      accessToken,
      decodedAccessToken,
      userPreference,
      authService,
      authState,
      isOpenSessionExpiryModal,
    },
  } = useAuthStore();

  const setIsOpenSessionExpiryModal = useCallback(
    (payload: boolean) =>
      dispatch({
        type: 'SET_IS_OPEN_SESSION_EXPIRY_MODAL',
        payload,
      }),
    [dispatch],
  );

  const getIdleDialogRemainingSeconds = useCallback(() => {
    if (authService) {
      return authService.getIdleDialogRemainingSeconds();
    }
    return null;
  }, [authService]);

  const login = useCallback(() => {
    if (authService) {
      authService.authLogin();
    }
  }, [authService]);

  const logout = useCallback(
    (config: Parameters<AuthService['authLogout']>[0]) => {
      if (authService) {
        authService.authLogout(config);
      }
    },
    [authService],
  );

  const onIdleDialogClosed = useCallback(() => {
    if (authService) {
      authService.onIdleDialogClosed();
    }
  }, [authService]);

  useEffect(() => {
    // Do not redirect to login page if the user is on the failure page
    // to avoid redirect loop
    if (isPublicPage) {
      return;
    }
    // with strict mode React.useEffect is called twice
    // https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-re-running-effects-in-development
    if (isInitialized.current) {
      // do not initialize twice
      return;
    }
    isInitialized.current = true;
    // hermes is not designed for server side rendering
    // it's accessing the window on initialization
    // and throw an error if we static import it
    import('../auth/auth-service-static')
      .then(({ AuthServiceStatic }) => {
        console.log('DEBUG - auth: init');
        const initData = hermesConfig;
        AuthServiceStatic.init({
          ...initData,
          onStateChange: (newAuthState) => {
            switch (newAuthState.type) {
              case hermesServiceEventType.enum.login: {
                const redirectConfig = handleRedirectAfterLogin({
                  pathname,
                  searchParams,
                  // hardcode the home page for robinhood
                  homePagePathname: isRobinhood ? flightRoutes.home : undefined,
                  savedUrl:
                    newAuthState.payload.transactionAppState?.currentUrl,
                });

                if (redirectConfig.shouldRedirect) {
                  // use replace to avoid redirect loop
                  // for example A -> B -> C
                  // if we use push in C, the user will be redirected to B
                  // and then redirected to C again
                  router.replace(redirectConfig.nextPathname);
                }

                // We have to wait for the redirect to complete before we allow the home page redirect
                // otherwise the redirect login in the home page will overrider the pathname we replace above
                setTimeout(() => {
                  updateShouldAllowHomeRedirect(true);
                }, 2000);

                dispatch({
                  type: 'SET_AUTH_STATE',
                  payload: newAuthState.type,
                });
                dispatch({
                  type: 'SET_AUTH_INFO',
                  payload: {
                    userPreference: newAuthState.payload.userPreference,
                    user: newAuthState.payload.user,
                    accessToken: newAuthState.payload.accessToken,
                    decodedAccessToken: newAuthState.payload.decodedAccessToken,
                  },
                });

                break;
              }
              case hermesServiceEventType.enum.update: {
                dispatch({
                  type: 'SET_AUTH_INFO',
                  payload: {
                    userPreference: newAuthState.payload.userPreference,
                    user: newAuthState.payload.user,
                    accessToken: newAuthState.payload.accessToken,
                    decodedAccessToken: newAuthState.payload.decodedAccessToken,
                  },
                });

                break;
              }
              case hermesServiceEventType.enum.logout: {
                dispatch({
                  type: 'SET_AUTH_STATE',
                  payload: newAuthState.type,
                });
                authService.authLogin();
                break;
              }
              case hermesServiceEventType.enum.openSessionExpiredDialog: {
                dispatch({
                  type: 'SET_IS_OPEN_SESSION_EXPIRY_MODAL',
                  payload: true,
                });
                break;
              }
              case hermesServiceEventType.enum.closeSessionExpiredDialog: {
                dispatch({
                  type: 'SET_IS_OPEN_SESSION_EXPIRY_MODAL',
                  payload: false,
                });
                break;
              }
            }
          },
          onError: (error) => {
            sentryLog.error(error);
          },
        });

        const authService = AuthServiceStatic.getInstance();

        dispatch({
          type: 'SET_AUTH_SERVICE',
          payload: authService,
        });
        // Do not init auth if the user is on the sessionExpired page
        if (isSessionExpired) {
          return;
        }

        authService.initAuth().catch((error) => {
          sentryLog.error(error);
          let errorMessage =
            error.message || 'Cannot validate authentication info';
          // default code
          let code = error.code || 'FE0003';
          if (error.code === 'HM08') {
            errorMessage = 'Timeout - Cannot validate authentication info';
            code = 'HM08';
          }
          router.push(
            `/failure?code=${code}&error=${errorMessage}&httpCode=200`,
          );
        });
      })
      .catch((error) => {
        // TODO report sentry
        // Should we perform a hard refresh on the browser here?
        console.error('auth-service-static import error', error);
      });

    // We need to init the hermes service here
    // that's don't need to add authService to the dependency array
  }, [
    authState,
    dispatch,
    hermesConfig,
    isPublicPage,
    isRobinhood,
    isSessionExpired,
    pathname,
    router,
    searchParams,
    sentryLog,
    updateShouldAllowHomeRedirect,
  ]);

  return (
    <>
      {
        <AuthContext.Provider
          value={{
            accessToken,
            decodedAccessToken,
            authState,
            user,
            userPreference,
            getIdleDialogRemainingSeconds,
            login,
            logout,
            isOpenSessionExpiryModal,
            setIsOpenSessionExpiryModal,
            onIdleDialogClosed,
          }}
        >
          <SetSentryUser user={user}>{children}</SetSentryUser>
        </AuthContext.Provider>
      }
    </>
  );
}
