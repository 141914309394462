'use client';
import { cn } from '@/utils/tailwind';
import * as RadixDialog from '@radix-ui/react-dialog';
import { forwardRef, ReactNode } from 'react';

export interface DialogProps {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  contentWrapperClass?: string;
}

export const Dialog = (props: DialogProps) => {
  return (
    <RadixDialog.Root open={props.open}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="fixed inset-0 z-[11] bg-neutral-900 opacity-70" />
        <RadixDialog.Content
          className="fixed bottom-0 left-0 right-0 top-0 z-[12] flex items-center justify-center p-4 focus:outline-0 lg:p-0"
          onEscapeKeyDown={props.onClose}
        >
          <div
            className={cn(
              'w-full max-w-[600px] rounded-lg bg-neutral-100 px-4 py-10 lg:p-10',
              props.contentWrapperClass
            )}
          >
            {props.children}
          </div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export const DialogTitle = (props: {
  children: ReactNode;
  className?: string;
}) => (
  <RadixDialog.Title className={cn('text-heading-2', props.className)}>
    {props.children}
  </RadixDialog.Title>
);

export const DialogDescription = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    className?: string;
  }
>((props, ref) => (
  <RadixDialog.DialogDescription ref={ref} className={cn(props.className)}>
    {props.children}
  </RadixDialog.DialogDescription>
));

DialogDescription.displayName = 'DialogDescription';
