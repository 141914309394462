'use client';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { MenuItemGroupType, MenuItemType } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';

import { RedirectModalContext } from '@/app/redirect-modal-provider';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import React, { useContext } from 'react';
import { maxItemsForSingleLayer } from './constants';
import { NavigationMenuItem } from './navigation-menu-item';
import { customNavigation } from './utils';

type SingleMenuLayerType = {
  groupType: MenuItemGroupType;
  title: string;
  items: MenuItemType[];
  enabled: boolean;
};

export function HeaderNavigationV2(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      features: RewardsCentralConfiguration['application']['features'];
      navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
    },
) {
  const {
    headerLinks,
    earnHeaderLinks,
    redeemHeaderLinks,
    accountHeaderLinks,
  } = useHeaderLinks(props.features, props.navigationConfiguration);

  const { t } = useTranslation();

  const isMultiLayer = headerLinks.length > maxItemsForSingleLayer;

  const navigationLayers: SingleMenuLayerType[] = [
    {
      groupType: 'redeem' as const,
      title: t('Redeem Rewards'),
      items: redeemHeaderLinks,
      enabled: isMultiLayer && redeemHeaderLinks.length > 0,
    },
    {
      groupType: 'earn' as const,
      title: t('Earn More'),
      items: earnHeaderLinks,
      enabled: isMultiLayer && earnHeaderLinks.length > 0,
    },
    {
      groupType: 'redeem' as const,
      title: t('Redeem Rewards'),
      items: headerLinks,
      enabled: !isMultiLayer && headerLinks.length > 0,
    },
    {
      groupType: 'myAccount' as const,
      title: t('My account'),
      items: accountHeaderLinks,
      enabled: true,
    },
  ].filter((item: SingleMenuLayerType) => item.enabled);

  return <DesktopNaviation {...props} navigationLayers={navigationLayers} />;
}

function DesktopNaviation(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      navigationLayers: SingleMenuLayerType[];
    },
) {
  const { t } = useTranslation();
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const [focusOn, setFocusOn] = React.useState<string>('');
  const tenantHubConfig = useTenantHubConfig();
  const backgroundColor =
    tenantHubConfig?.header_color ?? props.backgroundColor;
  const tenantHubHeaderFontColor =
    tenantHubConfig?.header_font_color ?? props.color;

  return (
    <NavigationMenu.Root
      className="h-full"
      onValueChange={(value) => {
        setFocusOn(value);
      }}
    >
      <NavigationMenu.List className="h-full lg:flex lg:gap-x-6">
        {props.navigationLayers.map((layer, id) => {
          return (
            <NavigationMenu.Item
              key={layer.groupType}
              value={`${layer.groupType}-menu-group`}
              className="flex justify-center"
              data-state={id === 0 ? 'open' : undefined}
            >
              <NavigationMenu.Trigger
                className={cn('text-header', {
                  'opacity-25':
                    focusOn !== `${layer.groupType}-menu-group` &&
                    focusOn !== '',
                })}
              >
                {t(layer.title)}
              </NavigationMenu.Trigger>

              <NavigationMenu.Content
                className={cn(
                  'border-t border-neutral-400',
                  'absolute left-0 top-full w-full py-8',
                )}
                style={{ backgroundColor: backgroundColor }}
                data-testid={`menu-group-${layer.groupType}`}
              >
                <NavigationMenu.Sub className="mx-auto container-responsive">
                  <ul className="grid grid-cols-4 gap-x-12 gap-y-10">
                    {layer.items.map((item) => (
                      <NavigationMenuItem
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement>,
                        ) => {
                          customNavigation(item, event, setShowRedirectModal);
                        }}
                        key={item.translation}
                        menuItem={item}
                        className="-mx-4 -my-3 px-4 py-3 text-neutral-100 hover:bg-bg-header-600"
                        href={item.href}
                        displayMode="full"
                        style={{
                          ...(tenantHubHeaderFontColor
                            ? { color: tenantHubHeaderFontColor }
                            : {}),
                        }}
                      />
                    ))}
                  </ul>
                </NavigationMenu.Sub>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
          );
        })}
      </NavigationMenu.List>
      <NavigationMenu.Viewport />
    </NavigationMenu.Root>
  );
}
